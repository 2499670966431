import cssStyles from "./Alphabet.module.css"

import {letters_hiragana} from "../letters_hiragana"
import {letters_katakana} from "../letters_katakana"

export default function Alphabet(props) {

    const keys = Object.keys(letters_hiragana)


    return <div className={cssStyles.alphabet}>
        <table>
            <thead>
                <tr>
                    <th>sound</th>
                    <th>Hiragana</th>
                    <th>Katakana</th>
                </tr>
            </thead>

            {keys.map(k=>{

                return <tr key={k}>
                    <td>{k}</td>
                    <td>{letters_hiragana[k]}</td>
                    <td>{letters_katakana[k]}</td>
                </tr>

            })
            }
        </table>
    </div>
}
