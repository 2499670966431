import cssStyles from "./WordRoller.module.css"
import {useCallback, useEffect, useState} from "react"

import {letters_hiragana} from "../letters_hiragana"
import {words} from "../words"
import {Button} from "@mui/material";

import {playSound} from "../utils"

export default function WordRoller(props) {

    // https://sites.psu.edu/symbolcodes/languages/asia/japanese/hiraganachart/#cons

    console.log("WordRoller->props: ", props.set)

    //const sounds = Object.keys(letters)
    const [englishWords] = useState(Object.keys(props?.set || words))
    console.log("englishWords.length: ", englishWords?.length)
    const [sound, setSound] = useState()
    const [word, setWord] = useState()
    const [japaneseWord, setJapaneseWord] = useState()
    const [isWordHidden, setIsWordHidden] = useState(false)
    const [isSoundHidden, setIsSoundHidden] = useState(true)
    //const [isLearningWords, setIsLearningWords] = useState(false)
    const isLearningWords = false



    //change when word changes

        useEffect(()=>{
            if (!word) {
                return
            }
            let japaneseWordSounds = words[word]
            console.log("update sound for word: ", word)
            console.log("japanese sounds: ", japaneseWordSounds)
            if (japaneseWordSounds) {
                setJapaneseWord(japaneseWordSounds)
                let vowels = japaneseWordSounds.split(/[ -]+/)
                console.log("vowels: ", vowels)
                let soundVowels = vowels.map((vowel, index) => {
                    console.log("vowel: ", vowel)
                    let theKey = word + vowel+""+index
                    console.log("key:", theKey)
                    if (!letters_hiragana[vowel]) {
                        console.log("ERR: UNRECOGNIZED/BAD VOWEL -> '" + vowel + "'")
                    }
                    let aSound = {...letters_hiragana[vowel], key:theKey}

                    console.log("sound: ", aSound)
                    return aSound
                })
                setIsSoundHidden(true)
                console.log("soundVowels: ", soundVowels)
                setSound(soundVowels)

                console.log("fetch started...")
            }
        }, [word])


    //only once
    useEffect(() => {
        let aWord = englishWords[Math.floor(Math.random()*englishWords.length)]
        setWord(aWord)
        console.log("new word selected: ", aWord)
    }, [englishWords]);

    const invertSoundHidden = useCallback(() => {
        console.log("invertSoundHidden called")
        if (isLearningWords) {
            console.log("ignoring, not learning words")
            return;
        }

        setIsSoundHidden(!isSoundHidden)
    }, [isLearningWords, isSoundHidden])

    const invertWordHidden = useCallback(() => {
        console.log("invertWordHidden called")
        if (!isLearningWords) {
            console.log("ignoring, not learning words")
            return;
        }

        setIsWordHidden(!isWordHidden)
    }, [isLearningWords, isWordHidden])



    return (
           <table id={cssStyles.navigationTable}>
               <tbody>
                <tr key={"navtab-tr-sound"}>
                    <td key={"navtab-td-sound"}>
                        <span
                            key={"navtab-span-sound"}
                            className={cssStyles.unselectable + " " + cssStyles.sound + (isSoundHidden?" " +cssStyles.hidden:"")}
                            onClick={invertSoundHidden}
                        >
                            {sound}
                        </span>
                        <br className={cssStyles.unselectable}/>
                        <span
                            key={"navtab-span-japanese-word"}
                            className={cssStyles.unselectable + " " + cssStyles.japaneseWord + (isSoundHidden?" " +cssStyles.hidden:"")}
                        >
                            {japaneseWord}
                        </span>
                        <br className={cssStyles.unselectable}/>
                        <Button
                            variant="contained"
                            onClick={invertSoundHidden}
                        >{isSoundHidden?"show":"hide"}</Button>
                        <Button
                            variant="contained"
                            onClick={()=>{playSound(japaneseWord)}}
                        >Say it!</Button>
                    </td>
                </tr>
                <tr key={"navtab-tr-word"}>
                    <td key={"navtab-td-word"}>
                        <span
                            key={"navtab-span-word"}
                            className={cssStyles.unselectable + " " + cssStyles.word + (isWordHidden?" " +cssStyles.hidden:"")}
                            onClick={invertWordHidden}
                        >{word}</span>
                    </td>
                </tr>
                <tr key={"navtab-tr-next-word-button"}>
                    <td key={"navtab-td-next-word-button"}>
                        <span
                            key={"navtab-span-next-word-button"}
                            className={cssStyles.unselectable + " " + cssStyles.nextWord}
                            onClick={()=>{setWord(englishWords[Math.floor(Math.random()*englishWords.length)])}}
                        >&rarr;</span>
                    </td>
                </tr>
               </tbody>
           </table>
    )
}
