import cssStyles from "./LetterRoller.module.css"
import {useEffect, useState} from "react"

import {letters_hiragana} from "../letters_hiragana"
import {letters_katakana} from "../letters_katakana"
import {Button} from "@mui/material";

export default function LetterRoller({alphabeth}) {

    // https://sites.psu.edu/symbolcodes/languages/asia/japanese/hiraganachart/#cons

    console.log("alphabeth: ", alphabeth)

    const [activeAlphabeth, setActiveAlphabeth] = useState([])
    const [sound, setSound] = useState()
    const [letter, setLetter] = useState()
    const [isLetterHidden, setIsLetterHidden] = useState(false)
    const [isSoundHidden, setIsSoundHidden] = useState(true)
    const [isLearningLetters] = useState(true)

    const randomlySelectASound = () => {
        console.log("randomlySelectASound called")
        const aSound = Object.keys(activeAlphabeth)[Math.floor(Math.random()*Object.keys(activeAlphabeth).length)]
        const aLetter = activeAlphabeth[aSound]
        setLetter(aLetter)
        setSound(aSound)
        console.log("Selected aSound: ", aSound)
        console.log("Selected aLetter: ", aLetter)
    }

    useEffect(() => {
        console.log("alphabeth changed to: ", alphabeth)
        setActiveAlphabeth((alphabeth === "hiragana"?letters_hiragana:letters_katakana))
    }, [alphabeth]);

    useEffect(() => {
        console.log("activeAlphabeth changed")
        randomlySelectASound()
        // eslint-disable-next-line
    }, [activeAlphabeth]);

    const invertSoundHidden = () => {
        if (!isLearningLetters) {
            return;
        }
        setIsSoundHidden(!isSoundHidden)
        if (!isSoundHidden) {
            randomlySelectASound()
        }
    }
    const invertLetterHidden = () => {
        if (isLearningLetters) {
            return;
        }
        setIsLetterHidden(!isLetterHidden)
    }

    return (
        <table id={cssStyles.navigationTable}>
            <tbody>
            <tr key={"navtab-tr-sound"}>
                <td key={"navtab-td-sound"}>
                        <span
                            key={"navtab-span-sound"}
                            className={cssStyles.unselectable + " " + cssStyles.sound + (isSoundHidden?" " +cssStyles.hidden:"")}
                            onClick={invertSoundHidden}
                        >
                            {sound}
                        </span>
                    <br className={cssStyles.unselectable}/>
                    <Button
                        variant="contained"
                        onClick={invertSoundHidden}
                    >{isSoundHidden?"show":"next"}</Button>
                </td>
            </tr>
            <tr key={"navtab-tr-letter"}>
                <td key={"navtab-td-letter"}>
                        <span
                            key={"navtab-span-letter"}
                            className={cssStyles.unselectable + " " + cssStyles.letter + (isLetterHidden?" " +cssStyles.hidden:"")}
                            onClick={invertLetterHidden}
                        >{letter}</span>
                </td>
            </tr>
            <tr key={"navtab-tr-next-letter-button"}>
                <td key={"navtab-td-next-letter-button"}>
                        <span
                            key={"navtab-span-next-letter-button"}
                            className={cssStyles.unselectable + " " + cssStyles.nextLetter}
                            onClick={randomlySelectASound}
                        >&rarr;</span>
                </td>
            </tr>
            </tbody>
        </table>
    )
}
