//http://www.cheat-sheets.org/sites/utf.ru/tables/hiragana.html
export const letters_hiragana = {
    "a":  <>&#x3042;</>,
    "ā":  <>&#x3042;ー</>,
    "i":  <>&#x3044;</>,
    "ī":  <>&#x3044;ー</>,
    "u":  <>&#x3046;</>,
    "ū":  <>&#x3046;ー</>,
    "e":  <>&#x3048;</>,
    "ē":  <>&#x3048;ー</>,
    "o":  <>&#x304A;</>,
    "ō":  <>&#x304A;ー</>,

    "ka": <>&#x304B;</>,
    "ga": <>&#x304C;</>,
    "ki": <>&#x304D;</>,
    "gi": <>&#x304E;</>,
    "ku": <>&#x304F;</>,
    "gu": <>&#x3050;</>,
    "ke": <>&#x3051;</>,
    "ge": <>&#x3052;</>,
    "ko": <>&#x3053;</>,
    "kō": <>&#x3053;ー</>,
    "go": <>&#x3054;</>,

    "sa": <>&#x3055;</>,
    "za": <>&#x3056;</>,
    "si": <>&#x3057;</>,
    "shi": <>&#x3057;</>, //same as SI https://www.unicode.org/charts/PDF/U3040.pdf
    "shyu":<>&#x3057;&#x3085;</>,
    "zi": <>&#x3058;</>,
    "ji": <>&#x3058;</>, //same as ZI https://www.unicode.org/charts/PDF/U3040.pdf
    "su": <>&#x3059;</>,
    "sū": <>&#x3059;&#x3046;</>,
    "zu": <>&#x305A;</>,
    "se": <>&#x305B;</>,
    "ze": <>&#x305C;</>,
    "so": <>&#x305D;</>,
    "zo": <>&#x305E;</>,

    "ta": <>&#x305F;</>,
    "da": <>&#x3060;</>,
    "ti": <>&#x3061;</>,
    "chi": <>&#x3061;</>, //same as TI https://www.unicode.org/charts/PDF/U3040.pdf
    "di": <>&#x3062;</>,
    "tu": <>&#x3064;</>,
    "tsu": <>&#x3064;</>, //same as TU https://www.unicode.org/charts/PDF/U3040.pdf
    "dju": <>&#x3065;</>,
    "te": <>&#x3066;</>,
    "de": <>&#x3067;</>,
    "to": <>&#x3068;</>,
    "do": <>&#x3069;</>,

    "na": <>&#x306A;</>,
    "ni": <>&#x306B;</>,
    "nu": <>&#x306C;</>,
    "ne": <>&#x306D;</>,
    "no": <>&#x306E;</>,

    "ha": <>&#x306F;</>,
    "ba": <>&#x3070;</>,
    "pa": <>&#x3071;</>,


    "hi": <>&#x3072;</>,
    "hī": <>&#x3072;ー</>,
    "bi": <>&#x3073;</>,
    "pi": <>&#x3074;</>,

    "hu": <>&#x3075;</>,
    "fu": <>&#x3075;</>, //same as HU https://www.unicode.org/charts/PDF/U3040.pdf
    "bu": <>&#x3076;</>,
    "pu": <>&#x3077;</>,

    "he": <>&#x3078;</>,
    "be": <>&#x3079;</>,
    "pe": <>&#x307A;</>,

    "ho": <>&#x307B;</>,
    "bo": <>&#x307C;</>,
    "po": <>&#x307D;</>,

    "ma": <>&#x307E;</>,
    "mi": <>&#x307F;</>,
    "mu": <>&#x3080;</>,
    "me": <>&#x3081;</>,
    "mo": <>&#x3082;</>,

    "ya": <>&#x3084;</>,
    "yu": <>&#x3086;</>,
    "yo": <>&#x3088;</>,

    "ra": <>&#x3089;</>,
    "ri": <>&#x308A;</>,
    "ru": <>&#x308B;</>,
    "re": <>&#x308C;</>,
    "ro": <>&#x308D;</>,

    "wa": <>&#x308F;</>,
    "wi": <>&#x3090;</>,
    "we": <>&#x3091;</>,
    "wo": <>&#x3092;</>,

    "n":  <>&#x3093;</>,

    "vu": <>&#x3094;</>,

    "kya": <>&#x304D;&#x3083;</>,
    "kyu": <>&#x304D;&#x3085;</>,
    "kyo": <>&#x304D;&#x3087;</>,

    "gya": <>&#x304E;&#x3083;</>,
    "gyu": <>&#x304E;&#x3085;</>,
    "gyo": <>&#x304E;&#x3087;</>,

    "sha": <>&#x3057;&#x3083;</>,
    "shu": <>&#x3057;&#x3085;</>,
    "sho": <>&#x3057;&#x3087;</>,

    "ja": <>&#x3058;&#x3083;</>,
    "ju": <>&#x3058;&#x3085;</>,
    "jo": <>&#x3058;&#x3087;</>,

    "cha": <>&#x3061;&#x3083;</>,
    "chu": <>&#x3061;&#x3085;</>,
    "cho": <>&#x3061;&#x3087;</>,

    "hya": <>&#x3072;&#x3083;</>,
    "hyu": <>&#x3072;&#x3085;</>,
    "hyo": <>&#x3072;&#x3087;</>,

    "bya": <>&#x3073;&#x3083;</>,
    "byu": <>&#x3073;&#x3085;</>,
    "byo": <>&#x3073;&#x3087;</>,

    "pya": <>&#x3074;&#x3083;</>,
    "pyu": <>&#x3074;&#x3085;</>,
    "pyo": <>&#x3074;&#x3087;</>,

    "mya": <>&#x307F;&#x3083;</>,
    "myu": <>&#x307F;&#x3085;</>,
    "myo": <>&#x307F;&#x3087;</>,

    "nya": <>&#x306B;&#x3083;</>,
    "nyu": <>&#x306B;&#x3085;</>,
    "nyo": <>&#x306B;&#x3087;</>,

    "rya": <>&#x308A;&#x3083;</>,
    "ryu": <>&#x308A;&#x3085;</>,
    "ryo": <>&#x308A;&#x3087;</>,

    "ttsu": <>&#x3063;&#x3064;</>,

    "chyou": <>&#x3061;&#x3087;&#x3063;</>

}
