/*
import {useEffect, useState} from "react"
import {words} from "../words"
import {getRomajiAsHiraganaVowels} from "../utils"
import {Button} from "@mui/material";
import {playSound} from "../utils"

 */
export default function WordList(props) {



    // https://sites.psu.edu/symbolcodes/languages/asia/japanese/hiraganachart/#cons

    //console.log("WordList->props: ", props.set)

    //const [paging] = useState(props.paging)
    //const [pageSize] = useState(props.pageSize || 10)

    //const sounds = Object.keys(letters)

    /*
    const [englishWords] = useState(Object.keys(props.set))

    const columns = [
        { title: "English", field: "englishWord"},
        { title: "Romaji Vowels", field: "romajiVowels" },
        { title: "Romaji Notation", field: "romajiNotation"},
        { title: "Spoken", field: "spoken"},
    ];

    const [tableData, setTableData] = useState([])

    useEffect(()=>{

        let data = englishWords.sort((a, b) => (a.toLowerCase().localeCompare(b.toLowerCase()))).map(englishWordKey => {

            let romajiVowels = words[englishWordKey]
            if (!romajiVowels) {
                romajiVowels = "-"
            }

            return {
                englishWord: englishWordKey,
                "romajiVowels": romajiVowels,
                romajiNotation: getRomajiAsHiraganaVowels(words[englishWordKey]),
                spoken: <Button
                    variant="contained"
                    onClick={()=>{playSound(romajiVowels)}}
                >Say it!</Button>
            }

        })

        //console.log("DATA: ", data)
        setTableData(data)

    }, [englishWords])
     */


    //kudos: https://blog.logrocket.com/material-table-react-tutorial-with-examples/
    //TODO return <MaterialTable options={{paging: paging, pageSize: pageSize, sorting: true}} title="All the words" style={{width: "100%"}} columns={columns} data={tableData} />;
    return <div>WordList WIP</div>
}
