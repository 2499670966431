import cssStyles from "./Home.module.css"
import {Grid, Paper} from "@mui/material";

export default function Home(props) {

    const topics = [
        {title: "Text to Hiragana"},
        {title: "Exercise Writing with a Pencil"},
        {title: "Letter Writing"},
        {title: "Loads of categories"},
        {title: "Sentences"},
        {title: "Reading"},
    ]
    const spacing = 2

    return <div className={cssStyles.homeDiv}>
        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={spacing}>
                    {topics.map((value) => (
                        <Grid key={value.title} item>
                            <Paper
                                sx={{
                                    height: 140,
                                    width: 300,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                }}
                            >{value.title}</Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    </div>
}
