import {useEffect, useState} from "react"
import styles from "./SpeechToHiragana.module.css"
import {useReactMediaRecorder} from "react-media-recorder"
import {copyTextToClipboard, getFileFormatsSupportInformation} from "../utils"

function truncData(dataString) {
    const first10 = dataString.substring(0, 10)
    const last10 = dataString.substring(dataString.length - 10)
    return first10 + "..." + last10
}

function getMediaType() {
    var supportedFileFormatTypes = getFileFormatsSupportInformation().filter(ff => ff.supported).map(ff => ff.type)
    if (supportedFileFormatTypes.length > 0) {
        //TODO admittedly very crude way, because maybe the default of the mediarecorder is the second option or the third or ...
        return supportedFileFormatTypes[0]
    }
    return "-";
}

export default function SpeechToHiragana(props) {

    const [recognizedText, setRecognizedText] = useState()
    const [translatedText, setTranslatedText] = useState()
    const [isRecording, setIsRecording] = useState(false)

    useEffect(() => {
        if (!recognizedText || "-" === recognizedText || recognizedText.trim().length === 0) {
            setTranslatedText("-")
            console.log("recognizedText empty, skipping translation")
            return
        }
        console.log("calling TRANSLATE API for: ", recognizedText)
        fetch(
            `/.netlify/functions/get-text-from-google-translate-API`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    from: "ja",
                    to: "en",
                    text: recognizedText,
                    mediaType: getMediaType()
                })
            }
        )
            .then((res) => {
                console.log("res.json(): ", res)
                return res.json()
            })
            .then((data) => {
                console.log("data: ", data)
                if (!data || data.error) {
                    console.log("ERROR: " + data?.message)
                    setTranslatedText("-")
                } else {
                    if (data.translatedText) {
                        setTranslatedText(data.translatedText)
                    }
                }
            }).catch((e)=>{
            console.log("error getting data: ", e)
        })
    }, [recognizedText])

    const {
        status,
        startRecording,
        stopRecording,
        mediaBlobUrl,
    } = useReactMediaRecorder({
        video: false,
        audio: true
    });

    console.log(status)

    useEffect((arg)=> {
        console.log("upload: ", arg)
        console.log("blob: ", mediaBlobUrl)

        //first get the content of mediaBlobUrl and then send it to the backend

        const convertBlobToBase64 = async (blob) => { // blob data
            return await blobToBase64(blob);
        }

        const blobToBase64 = blob => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

        if (!mediaBlobUrl) {
            console.log("mediaBlobUrl undefined")
            return;
        }

        fetch(
            mediaBlobUrl, {
                method: 'GET'
            }
        )
            .then(response => {
                if (!response || !response.url) {
                    console.log("response or response.url is undefined")
                    return
                }
                fetch(response.url)
                    .then(r => r.blob())
                    .then(async (blob) => {
                        console.log("--blob: ", blob)
                        const mediaType = getMediaType()
                        console.log("media type used in browser: ", mediaType)

                        let base64string = await convertBlobToBase64(blob)

                        let base64stringWithoutbeginning = base64string
                            .replace(/^data:audio\/\w+;base64,/, "")
                            .replace(/^data:text\/\w+;base64,/, "")

                        console.log("base64string: ", truncData(base64stringWithoutbeginning))

                        fetch(
                            `/.netlify/functions/get-text-from-voice-with-google-speech-API`, {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    mediaType: mediaType,
                                    base64audio: base64stringWithoutbeginning
                                })
                            }
                        )
                            .then((res) => {
                                console.log("res.json(): ", res)
                                return res.json()
                            })
                            .then((data) => {
                                console.log("data: ", data)
                                if (!data || data.error) {
                                    //setRecognizedText("ERROR: " + data?.message)
                                    setRecognizedText("-")
                                } else {
                                    if (data.recognized && data.recognized.transcript) {
                                        setRecognizedText(data.recognized.transcript)
                                    } else {
                                        setRecognizedText("No transcript")
                                    }
                                }
                            }).catch((e) => {
                            console.log("error getting data: ", e)
                        })

                        return base64string;
                    })
            })


    }, [mediaBlobUrl])

    function copyToClipboard(text) {
        console.log("text", text)
        copyTextToClipboard(text)
        alert("copied to clipboard: " + text)
    }

    return <div key={"TextToHiragana-div"}>
        <span key={"input-span"} className={styles.japaneseWordSounds}>
            <div className={styles.controlsColumn}>
                <span
                    onClick={()=>copyToClipboard(recognizedText)}
                    className={styles.hiraganaSpan}
                >{recognizedText}</span>
                <span
                    onClick={()=>copyToClipboard(translatedText)}
                    className={styles.englishSpan}
                >{translatedText}</span>
                <div>
                    <button
                        id="recorder"
                        className={(isRecording?styles.stopButton:styles.startButton)}
                        onMouseDown={()=> {
                            setIsRecording(true);
                            startRecording()
                        }}
                        onMouseUp={()=> {
                            setIsRecording(false)
                            stopRecording()
                        }}
                        onTouchStart={()=> {
                            setIsRecording(true);
                            startRecording()
                        }}
                        onTouchEnd={()=> {
                            setIsRecording(false)
                            stopRecording()
                        }}
                    >{isRecording?"Release to stop recording":"Press to start recording"}</button>
                </div>
                <audio
                    id="player"
                    src={mediaBlobUrl}
                    controls
                    type="audio/mpeg"
                />
            </div>
        </span>
    </div>;
}
