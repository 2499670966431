import cssStyles from "./LetterLearner.module.css"
import {useCallback, useEffect, useRef, useState} from "react"

import {Button} from "@mui/material";

import SignaturePad from "signature_pad";
import animals from "../animals.json";
import body from "../body.json";
import colors from "../colors.json";
import conjunctions from "../conjunctions.json";
import financial from "../financial.json";
import food from "../food.json";
import greetings from "../greetings.json";
import numbers from "../numbers.json";
import objects from "../objects.json";
import people from "../people.json";
import pronouns from "../pronouns.json";
import qualifiers from "../qualifiers.json";
import surroundings from "../surroundings.json";
import verbs from "../verbs.json";
import weather from "../weather.json";
import {getRomajiAsHiraganaVowels, getRomajiAsKatakanaVowels} from "../utils";

const words = {
    ...animals,
    ...body,
    ...colors,
    ...conjunctions,
    ...financial,
    ...food,
    ...greetings,
    ...numbers,
    ...objects,
    ...people,
    ...pronouns,
    ...qualifiers,
    ...surroundings,
    ...verbs,
    ...weather,
}

export default function LetterLearner(props) {

    console.log("HiraganaRecognizer")
    const [englishWords] = useState(Object.keys(props?.set || words))
    let [vowels, setVowels] = useState("")
    let [hiragana, setHiragana] = useState("")
    let [showHiragana, setShowHiragana] = useState(false)
    let [katakana, setKatakana] = useState("")
    let [showKatakana, setShowKatakana] = useState(false)
    let [word, setWord] = useState("")
    let [recognizedText, setRecognizedText] = useState("")
    let [base64image, setBase64Image] = useState()
    useEffect(() => {
        //console.log("calling recognize, base64image: ", base64image)
        if (!base64image || base64image.length === 0) {
            console.log("NOT calling recognize, because base 64 image absent or empty")
            return
        }
        console.log("calling recognize")
        fetch(
            `/.netlify/functions/get-text-from-image-with-google-vision-API`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    base64image: base64image
                })
            }
        )
            .then((res) => {
                console.log("res.json(): ", res)
                return res.json()
            })
            .then((data) => {
                console.log("data: ", data)
                if (!data || data.error) {
                    setRecognizedText("ERROR: " + data?.message)
                } else {
                    if (!data.responses) {
                        setRecognizedText(data)
                    } else if (!data.responses[0]) {
                        setRecognizedText("No response")
                    } else if (!data.responses[0].fullTextAnnotation) {
                        setRecognizedText("No fullTextAnnotation")
                    } else if (!data.responses[0].fullTextAnnotation.text) {
                        setRecognizedText("No text")
                    } else {
                        setRecognizedText(data.responses[0].fullTextAnnotation.text)
                    }
                }
            }).catch((e)=>{
            console.log("error getting data: ", e)
        })
    }, [base64image])

    const [signaturePadRef, setSignaturePadRef] = useState()

    const signaturePadCanvasRef = useRef()
    useEffect(() => {
        //https://github.com/szimek/signature_pad
        let canvaske = signaturePadCanvasRef.current
        const signaturePad = new SignaturePad(canvaske, {
            dotSize: 2,
            minWidth: 2,
            maxWidth: 2,
            penColor: "rgb(0, 0, 0)",
            backgroundColor: "rgb(255, 255, 255)",
            width: 250,
            height: 250
        })
        setSignaturePadRef(signaturePad)
    }, [])

    useEffect(() => {
        function resizeCanvas() {
            if (!signaturePadRef) {
                return
            }
            let canvaske = signaturePadCanvasRef.current
            const ratio =  Math.max(window.devicePixelRatio || 1, 1)
            canvaske.width = canvaske.offsetWidth * ratio
            canvaske.height = canvaske.offsetHeight * ratio
            canvaske.getContext("2d").scale(ratio, ratio)
            console.log("signaturePadRef: ", signaturePadRef)
            signaturePadRef.clear()  //otherwise isEmpty() might return incorrect value
            setRecognizedText("")
        }

        window.addEventListener("resize", resizeCanvas)
        resizeCanvas()
    }, [signaturePadRef])

    function undo() {
        if (!signaturePadRef) {
            return
        }
        let data = signaturePadRef.toData();
        if (data) {
            data.pop(); // remove the last dot or line
            signaturePadRef.fromData(data);
        }
    }

    const clear = useCallback(() => {
        if (signaturePadRef) {
            signaturePadRef.clear();
        }

        setRecognizedText("")
        const aWord = englishWords[Math.floor(Math.random()*englishWords.length)]
        setWord(aWord)
        setVowels(words[aWord])
        setHiragana(getRomajiAsHiraganaVowels(words[aWord]))
        setKatakana(getRomajiAsKatakanaVowels(words[aWord]))
    }, [englishWords, signaturePadRef])

    useEffect(()=>{
        clear()
    }, [clear])

    return (
        <table id={cssStyles.navigationTable}>
            <tbody>
            <tr key={"navtab-tr-vowels"}>
                <td key={"navtab-td-vowels"}>
                    <span className={cssStyles.wordSpan}>{word}</span>
                    &nbsp;
                    <span className={cssStyles.vowelsSpan}>({vowels})</span>
                    &nbsp;
                    <span
                        className={cssStyles.hiraganaSpan}
                        style={{"display":(showHiragana?"inline":"none")}}
                    >({hiragana})</span>
                    &nbsp;
                    <span
                        className={cssStyles.katakanaSpan}
                        style={{"display":(showKatakana?"inline":"none")}}
                    >({katakana})</span>
                </td>
            </tr>
            <tr key={"navtab-tr-signaturepad"}>
                <td key={"navtab-td-signaturepad"}>
                    <canvas
                        className={cssStyles.sigContainer}
                        ref={signaturePadCanvasRef}
                    />
                </td>
            </tr>
            <tr key={"navtab-tr-sound"}>
                <td key={"navtab-td-sound"}>
                    <Button
                        variant="contained"
                        onClick={()=>{undo()}}
                    >Undo</Button>
                    <Button
                        variant="contained"
                        onClick={()=>{signaturePadRef.clear(); setRecognizedText("")}}
                    >Clear</Button>
                    <Button
                        variant="contained"
                        onClick={()=>{clear()}}
                    >New</Button>
                    <Button
                        variant="contained"
                        onClick={()=>{setBase64Image(signaturePadRef.toDataURL())}}
                    >Read it!</Button>
                    <Button
                        variant="contained"
                        onClick={()=>{setShowHiragana(!showHiragana)}}
                    >{showHiragana ? "Hide" : "Show"} Hiragana</Button>
                    <Button
                        variant="contained"
                        onClick={()=>{setShowKatakana(!showKatakana)}}
                    >{showKatakana ? "Hide" : "Show"} Katagana</Button>
                </td>
            </tr>
            <tr key={"navtab-tr-recognized"}>
                <td key={"navtab-td-sound"}><span className={cssStyles.recognizedText}>{recognizedText}</span></td>
            </tr>
            </tbody>
        </table>
    )
}
