import animals from "./animals.json";
import body from "./body.json";
import clothing from "./clothing.json";
import colors from "./colors.json";
import concepts from "./concepts.json";
import conjunctions from "./conjunctions.json";
import countries from "./countries.json";
import daymonthyear from "./daymonthyear.json";
import family from "./family.json";
import financial from "./financial.json";
import food from "./food.json";
import greetings from "./greetings.json";
import languages from "./languages.json";
import placementAndDirections from "./placement_and_directions.json";
import numbers from "./numbers.json";
import objects from "./objects.json";
import people from "./people.json";
import pronouns from "./pronouns.json";
import qualifiers from "./qualifiers.json";
import sentences from "./sentences.json";
import surroundings from "./surroundings.json";
import time from "./time.json";
import travel from "./travel.json";
import verbs from "./verbs.json";
import weather from "./weather.json";
export const words = {
    ...animals,
    ...body,
    ...clothing,
    ...colors,
    ...concepts,
    ...conjunctions,
    ...countries,
    ...daymonthyear,
    ...family,
    ...financial,
    ...food,
    ...greetings,
    ...languages,
    ...placementAndDirections,
    ...numbers,
    ...objects,
    ...people,
    ...pronouns,
    ...qualifiers,
    ...sentences,
    ...surroundings,
    ...time,
    ...travel,
    ...verbs,
    ...weather,
}

