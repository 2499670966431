export const letters_katakana = {
    "a":  <>&#x30A2;</>,
    "ā":  <>&#x30A2;ー</>,
    "i":  <>&#x30A4;</>,
    "ī":  <>&#x30A4;ー</>,
    "u":  <>&#x30A6;</>,
    "ū":  <>&#x30A6;ー</>,
    "e":  <>&#x30A8;</>,
    "ē":  <>&#x30A8;ー</>,
    "o":  <>&#x30AA;</>,
    "ō":  <>&#x30AA;ー</>,

    "ka": <>&#x30AB;</>,
    "ga": <>&#x30AC;</>,
    "ki": <>&#x30AD;</>,
    "gi": <>&#x30AE;</>,
    "ku": <>&#x30AF;</>,
    "gu": <>&#x30B0;</>,
    "ke": <>&#x30B1;</>,
    "ge": <>&#x30B2;</>,
    "ko": <>&#x30B3;</>,
    "go": <>&#x30B4;</>,

    "sa": <>&#x30B5;</>,
    "za": <>&#x30B6;</>,
    "si": <>&#x30B7;</>,
    "shi": <>&#x30B7;</>, //same as SI https://www.unicode.org/charts/PDF/U3040.pdf
    "zi": <>&#x30B8;</>,
    "ji": <>&#x30B8;</>, //same as ZI https://www.unicode.org/charts/PDF/U3040.pdf
    "su": <>&#x30B9;</>,
    "zu": <>&#x30BA;</>,
    "se": <>&#x30BB;</>,
    "ze": <>&#x30BC;</>,
    "so": <>&#x30BD;</>,
    "zo": <>&#x30BE;</>,

    "ta": <>&#x30BF;</>,
    "da": <>&#x30C0;</>,
    "ti": <>&#x30C1;</>,
    "chi": <>&#x30C1;</>, //same as TI https://www.unicode.org/charts/PDF/U3040.pdf
    "di": <>&#x30C2;</>,
    "tu": <>&#x30C4;</>,
    "tsu": <>&#x30C4;</>, //same as TU https://www.unicode.org/charts/PDF/U3040.pdf
    "dju": <>&#x30C5;</>,
    "te": <>&#x30C6;</>,
    "de": <>&#x30C7;</>,
    "to": <>&#x30C8;</>,
    "do": <>&#x30C9;</>,

    "na": <>&#x30CA;</>,
    "ni": <>&#x30CB;</>,
    "nu": <>&#x30CC;</>,
    "ne": <>&#x30CD;</>,
    "no": <>&#x30CE;</>,

    "ha": <>&#x30CF;</>,
    "ba": <>&#x30D0;</>,
    "pa": <>&#x30D1;</>,

    "hi": <>&#x30D2;</>,
    "bi": <>&#x30D3;</>,
    "pi": <>&#x30D4;</>,

    "hu": <>&#x30D5;</>,
    "fu": <>&#x30D5;</>, //same as HU https://www.unicode.org/charts/PDF/U3040.pdf
    "bu": <>&#x30D6;</>,
    "pu": <>&#x30D7;</>,

    "he": <>&#x30D8;</>,
    "be": <>&#x30D9;</>,
    "pe": <>&#x30DA;</>,

    "ho": <>&#x30DB;</>,
    "bo": <>&#x30DC;</>,
    "po": <>&#x30DD;</>,

    "ma": <>&#x30DE;</>,
    "mi": <>&#x30DF;</>,
    "mu": <>&#x30E0;</>,
    "me": <>&#x30E1;</>,
    "mo": <>&#x30E2;</>,

    "ya": <>&#x30E4;</>,
    "yu": <>&#x30E6;</>,
    "yo": <>&#x30E8;</>,

    "ra": <>&#x30E9;</>,
    "ri": <>&#x30EA;</>,
    "ru": <>&#x30EB;</>,
    "re": <>&#x30EC;</>,
    "ro": <>&#x30ED;</>,

    "wa": <>&#x30EF;</>,
    "wi": <>&#x30F0;</>,
    "we": <>&#x30F1;</>,
    "wo": <>&#x30F2;</>,

    "n":  <>&#x30F3;</>,

    "vu": <>&#x30F4;</>,

    "kya": <>&#x30AD;&#x30E3;</>,
    "kyu": <>&#x30AD;&#x30E5;</>,
    "kyo": <>&#x30AD;&#x30E7;</>,

    "gya": <>&#x30AE;&#x30E3;</>,
    "gyu": <>&#x30AE;&#x30E5;</>,
    "gyo": <>&#x30AE;&#x30E7;</>,

    "sha": <>&#x30B7;&#x30E3;</>,
    "shu": <>&#x30B7;&#x30E5;</>,
    "sho": <>&#x30B7;&#x30E7;</>,

    "ja": <>&#x30B8;&#x30E3;</>,
    "ju": <>&#x30B8;&#x30E5;</>,
    "jo": <>&#x30B8;&#x30E7;</>,

    "cha": <>&#x30C1;&#x30E3;</>,
    "chu": <>&#x30C1;&#x30E5;</>,
    "cho": <>&#x30C1;&#x30E7;</>,

    "hya": <>&#x30D2;&#x30E3;</>,
    "hyu": <>&#x30D2;&#x30E5;</>,
    "hyo": <>&#x30D2;&#x30E7;</>,

    "bya": <>&#x30D3;&#x30E3;</>,
    "byu": <>&#x30D3;&#x30E5;</>,
    "byo": <>&#x30D3;&#x30E7;</>,

    "pya": <>&#x30D4;&#x30E3;</>,
    "pyu": <>&#x30D4;&#x30E5;</>,
    "pyo": <>&#x30D4;&#x30E7;</>,

    "mya": <>&#x30DF;&#x30E3;</>,
    "myu": <>&#x30DF;&#x30E5;</>,
    "myo": <>&#x30DF;&#x30E7;</>,

    "nya": <>&#x30CB;&#x30E3;</>,
    "nyu": <>&#x30CB;&#x30E5;</>,
    "nyo": <>&#x30CB;&#x30E7;</>,

    "rya": <>&#x30EA;&#x3083;</>,
    "ryu": <>&#x30EA;&#x3085;</>,
    "ryo": <>&#x30EA;&#x3087;</>,
}
