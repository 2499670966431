import './App.css';
import {useEffect, useState} from "react";
import WordRoller from "./components/WordRoller/WordRoller";
import WordList from "./components/WordList/WordList";

import animals from "./components/animals.json";
import body from "./components/body.json";
import colors from "./components/colors.json";
import concepts from "./components/concepts.json";
import conjunctions from "./components/conjunctions.json";
import countries from "./components/countries.json";
import daymonthyear from "./components/daymonthyear.json";
import family from "./components/family.json";
import financial from "./components/financial.json";
import food from "./components/food.json";
import greetings from "./components/greetings.json";
import languages from "./components/languages.json";
import numbers from "./components/numbers.json";
import objects from "./components/objects.json";
import people from "./components/people.json";
import pronouns from "./components/pronouns.json";
import qualifiers from "./components/qualifiers.json";
import sentences from "./components/sentences.json";
import surroundings from "./components/surroundings.json";
import time from "./components/time.json";
import verbs from "./components/verbs.json";
import weather from "./components/weather.json";
import {words} from "./components/words.js";

import LetterRoller from "./components/LetterRoller/LetterRoller";
import {Box, Button, Container} from "@mui/material";
import TextToHiragana from "./components/TextToHiragana/TextToHiragana";
import HiraganaRecognizer from "./components/HiraganaRecognizer/HiraganaRecognizer";
import WritingExerciser from "./components/WritingExerciser/WritingExerciser";
import LetterLearner from "./components/LetterLearner/LetterLearner";
import SpeechToHiragana from "./components/SpeechToHiragana/SpeechToHiragana";
import About from "./components/About/About";
import Home from "./components/Home/Home";

import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import HomeIcon from '@mui/icons-material/Home';
import TranslateIcon from '@mui/icons-material/Translate';
import CreateIcon from '@mui/icons-material/Create';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import PetsIcon from '@mui/icons-material/Pets';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import PsychologyIcon from '@mui/icons-material/Psychology';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FlagIcon from '@mui/icons-material/Flag';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CurrencyYenIcon from '@mui/icons-material/CurrencyYen';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import LanguageIcon from '@mui/icons-material/Language';
import NumbersIcon from '@mui/icons-material/Numbers';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import PeopleIcon from '@mui/icons-material/People';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import AbcIcon from '@mui/icons-material/Abc';
import StraightenIcon from '@mui/icons-material/Straighten';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import PasswordIcon from '@mui/icons-material/Password';
import InfoIcon from '@mui/icons-material/Info';
import TextFieldsIcon from '@mui/icons-material/TextFields';

import netlifyIdentity from "netlify-identity-widget";
import Alphabet from "./components/Alphabet/Alphabet";

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function DrawerItem(props) {
    const {children, name, label, icon, onClick} = props;

    return (
        <ListItem
            key={"draweritem-span-"+name}
            button
            onClick={(onClick?onClick:function(){
                console.log("warning: no onclick provided for DrawerItem")})}
        >
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText primary={label} />
            {children}
        </ListItem>
    )
}

function PageContent(props) {
    const { children, value, name, requires_logon, logon,  ...other } = props;


    return (
        (requires_logon && !logon?
            <div
                key={`simple-pagecontent-${name}`}
                className={"pagecontent"}
                hidden={value !== name}
                id={`simple-pagecontent-${name}`}
                aria-labelledby={`simple-page-${name}`}
                {...other}>
                <span className={"pleaseLogonSpan"} key={"pleas-log-in-" + name}>Please sign in first to use this feature...</span>
            </div> :
        <div
            key={`simple-pagecontent-${name}`}
            className={"pagecontent"}
            hidden={value !== name}
            id={`simple-pagecontent-${name}`}
            aria-labelledby={`simple-page-${name}`}
            {...other}
        >
            {value === name && (
                <Container>
                    <Box>
                        {children}
                    </Box>
                </Container>
            )}
        </div> )
    );
}

function WordListPage(props) {
    return <><WordRoller set={props.set}/><br/><WordList set={props.set}/></>
}

function App() {

    useEffect(()=>{
        netlifyIdentity.init();
    }, [])

    const [drawerOpen, setDrawerOpen] = useState(false)
    const [value, setValue] = useState("home");
    const [logon, setLogon] = useState("");

    netlifyIdentity.on("login", (user)=>{
        console.log("on login, user:", user)
        setLogon(user)
    })
    netlifyIdentity.on("logout", ()=>{
        console.log("on logout")
        setLogon(undefined)
    })

    useEffect(()=>{
        const user = netlifyIdentity.currentUser();
        console.log("user >> ", user)
        if (user && user.email) {
            setLogon(user)
        }
    }, [])

    const handleChange = (event, newValue) => {
        console.log("handling change: ", newValue)
        setDrawerOpen(false)
        setValue(newValue)
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    let drawerItemsConfig = [
        {name:"home", label: "Home", icon:<HomeIcon/>, component:<Home/>},
        {name:"text_to_hiragana", label: "Text To Hiragana", icon:<TextFieldsIcon/>, requiresLogon:"true", component:<TextToHiragana/>},
        {name:"letter_writing", label: "Letter Writing", icon:<TranslateIcon/>, requiresLogon:"true", component: <LetterLearner/>},
        {name:"exercise_writing", label: "Exercise Writing", icon:<HistoryEduIcon/>, requiresLogon:"true", component: <WritingExerciser/>},
        {name:"writing", label: "Writing", icon:<CreateIcon/>, requiresLogon:"true", component: <HiraganaRecognizer/>},
        {name:"speaking", label: "Speaking", icon:<RecordVoiceOverIcon/>, requiresLogon:"true", component: <SpeechToHiragana/>},
        {name:"animals", label: "Animals", icon:<PetsIcon/>, component: <WordListPage set={animals}/>},
        {name:"body", label: "Body", icon:<AccessibilityNewIcon/>, component: <WordListPage set={body}/>},
        {name:"colors", label: "Colors", icon:<ColorLensIcon/>, component: <WordListPage set={colors}/>},
        {name:"concepts", label: "Concepts", icon:<PsychologyIcon/>, component: <WordListPage set={concepts}/>},
        {name:"conjunctions", label: "Conjunctions", icon:<AddCircleOutlineIcon/>, component: <WordListPage set={conjunctions}/>},
        {name:"countries", label: "Countries", icon:<FlagIcon/>, component: <WordListPage set={countries}/>},
        {name:"day_month_year", label: "Day/Month/Year", icon:<CalendarMonthIcon/>, component: <WordListPage set={daymonthyear}/>},
        {name:"family", label: "Family", icon:<EscalatorWarningIcon/>, component: <WordListPage set={family}/>},
        {name:"financial", label: "Financial", icon:<CurrencyYenIcon/>, component: <WordListPage set={financial}/>},
        {name:"food", label: "Food", icon:<RamenDiningIcon/>, component: <WordListPage set={food}/>},
        {name:"greetings", label: "Greetings", icon:<EmojiPeopleIcon/>, component: <WordListPage set={greetings}/>},
        {name:"languages", label: "Languages", icon:<LanguageIcon/>, component: <WordListPage set={languages}/>},
        {name:"numbers", label: "Numbers", icon:<NumbersIcon/>, component: <WordListPage set={numbers}/>},
        {name:"objects", label: "Objects", icon:<TableRestaurantIcon/>, component: <WordListPage set={objects}/>},
        {name:"people", label: "People", icon:<PeopleIcon/>, component: <WordListPage set={people}/>},
        {name:"pronouns", label: "Pronouns", icon:<HighlightAltIcon/>, component: <WordListPage set={pronouns}/>},
        {name:"qualifiers", label: "Qualifiers", icon:<StraightenIcon/>, component: <WordListPage set={qualifiers}/>},
        {name:"sentences", label: "Sentences", icon:<LibraryBooksIcon/>, component: <WordListPage set={sentences}/>},
        {name:"surroundings", label: "Surroundings", icon:<NaturePeopleIcon/>, component: <WordListPage set={surroundings}/>},
        {name:"time", label: "Time", icon:<AccessTimeIcon/>, component: <WordListPage set={time}/>},
        {name:"verbs", label: "Verbs", icon:<SettingsAccessibilityIcon/>, component: <WordListPage set={verbs}/>},
        {name:"weather", label: "Weather", icon:<WbSunnyIcon/>, component: <WordListPage set={weather}/>},
        {name:"hiragana/katakana", label: "Hiragana/Katakana", icon:<AlignHorizontalCenterIcon/>, component: <Alphabet/>},
        {name:"letters-hiragana", label: "Letters - Hiragana", icon:<AbcIcon/>, component: <LetterRoller alphabeth={"hiragana"}/>},
        {name:"letters-katakana", label: "Letters - Katakana", icon:<AbcIcon/>, component: <LetterRoller alphabeth={"katakana"}/>},
        {name:"words", label: "Words", icon:<PasswordIcon/>, component: <WordList set={words} paging={true}/>},
        {name:"about", label: "About", icon:<InfoIcon/>, component: <About/>},
    ]

    const handleSignin = (e) => {
        netlifyIdentity.open()
    }

    const handleSignout = (e) => {
        netlifyIdentity.close();
        netlifyIdentity.logout()
    }

    return (
        <div
            className="App"
            style={{width:"100%"}}
        >

            <AppBar
                position="fixed"
                open={drawerOpen}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(drawerOpen && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        {/* kudos to https://www.unicode.org/cgi-bin/GetUnihanData.pl?codepoint=%E5%B8%AB */}
                        Kyoushi - &#x304D;&#x3087;&#x3046;&#x3057; - &#x30AD;&#x30E7;&#x30A6;&#x30B7; - &#x6559;&#x5E2B;
                    </Typography>
                    <div className={"statusMessageDiv"}>
                        {
                            (
                                logon && logon.email
                                    ?
                                    <span><b>{logon.email}</b>&nbsp;<Button variant="contained" onClick={(e)=>{handleSignout(e)}}>Sign out</Button></span>
                                    :
                                    <Button variant="contained" onClick={(e)=>{handleSignin(e)}}>Sign in</Button>
                            )
                        }
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                key={"drawer"}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={drawerOpen}
            >
                <DrawerHeader key={"drawer-header"}>
                    <IconButton onClick={handleDrawerClose}>
                        {<ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider key={"divider-one"}/>
                <List key={"list-one"}>
                    {drawerItemsConfig.map((dic)=> (
                        <DrawerItem
                            key={"draweritem-" + dic.name}
                            name={dic.name}
                            label={dic.label}
                            icon={dic.icon}

                            onClick={(e)=>{handleChange(e, dic.name)}}
                            >
                        </DrawerItem>))}
                </List>
            </Drawer>
            <header key={"header"} className="App-header">

            </header>
            <div className={"App-body"}>
                {
                    drawerItemsConfig.map((dci)=>{
                        return (<PageContent key={"page-content-" + dci.name} name={dci.name} value={value} requires_logon={dci.requiresLogon} logon={logon}>
                            {dci.component}
                        </PageContent>)
                    })
                }
            </div>
        </div>
    );
}

export default App;
