import {letters_hiragana} from "./letters_hiragana"
import {letters_katakana} from "./letters_katakana"
import {words} from "./words"

export function playSound(japaneseWordSounds) {
    let paramsObj = {jpsound: japaneseWordSounds}
    let paramsString = (new URLSearchParams(paramsObj)).toString()

    console.log("japaneseWordSounds: ", japaneseWordSounds)
    fetch(`/.netlify/functions/get-spoken?` + paramsString)
        .then((res) => res.json())
        .then((data) => {
            console.log("data: ", data)
            //const newURL = data?.message
            //play sound
            let audio = new Audio(data.url)
            audio.play()
        }).catch((e)=>{
        console.log("error getting data: ", e)
    })
}

export function getFileFormatsSupportInformation() {
    if (window.MediaRecorder === undefined) {
        return([]);
    } else {
        var contentTypes = [
            "audio/basic",
            "audio/L24",
            "audio/mpeg",
            "audio/mp4",
            "audio/webm",
            "audio/x-aiff",
            "audio/ogg",
            "audio/vorbis",
            "audio/vnd.wav",
            "video/webm",
            "video/webm;codecs=vp8",
            "video/x-matroska;codecs=avc1",
            "video/mp4;codecs=avc1",
            "video/invalid"];
        return contentTypes.map(contentType => {
            return {type: contentType, supported: MediaRecorder.isTypeSupported(contentType)}})
    }
}

export function getRomaji(englishWord) {
    return words[englishWord]
}

export function getRomajiVowels(englishWord) {
    return words[englishWord].split(/[ -]+/)
}

export function getRomajiAsHiraganaVowels(romajiWord) {
    if (!romajiWord) {
        return ["-"]
    }
    let vowels = romajiWord.split(/[ -]+/)
    //console.log("vowels: ", vowels)
    let hiraganaVowels = vowels.map((vowel, index) => {
        //console.log("vowel: ", vowel)
        let theKey = "key-" + vowel+""+index
        //console.log("key:", theKey)
        if (!letters_hiragana[vowel]) {
            console.log("ERR: UNRECOGNIZED/BAD VOWEL -> '" + vowel + "'")
            return ""
        }
        let aSound = {...letters_hiragana[vowel], key:theKey}

        //console.log("sound: ", aSound)
        return aSound
    })
    return hiraganaVowels
}
export function getRomajiAsKatakanaVowels(romajiWord) {
    if (!romajiWord) {
        return ["-"]
    }
    let vowels = romajiWord.split(/[ -]+/)
    //console.log("vowels: ", vowels)
    let KatakanaVowels = vowels.map((vowel, index) => {
        //console.log("vowel: ", vowel)
        let theKey = "key-" + vowel+""+index
        //console.log("key:", theKey)
        if (!letters_katakana[vowel]) {
            console.log("ERR: UNRECOGNIZED/BAD VOWEL -> '" + vowel + "'")
            return ""
        }
        let aSound = {...letters_katakana[vowel], key:theKey}

        //console.log("sound: ", aSound)
        return aSound
    })
    return KatakanaVowels
}
function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}
export function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
        console.error('Async: Could not copy text: ', err);
    });
}
