import {letters_hiragana} from "../letters_hiragana"
import {letters_katakana} from "../letters_katakana"
import {useEffect, useState} from "react";
import styles from "./TextToHiragana.module.css"
import {copyTextToClipboard} from "../utils"

export default function TextToHiragana(props) {

    const [hiraganaSound, setHiraganaSound] = useState()
    const [katakanaSound, setKatakanaSound] = useState()
    const [japaneseWordSounds, setJapaneseWordSounds] = useState("")

    useEffect(()=>{
        console.log("update vowels for japaneseWordSounds: ", japaneseWordSounds)
        let vowels = japaneseWordSounds.split(/[ -]+/)
        console.log("vowels: ", vowels)
        let hiraganaSoundVowels = vowels.map((vowel, index) => {
            console.log("vowel: ", vowel)
            let theKey = "key-" + vowel+""+index
            console.log("key:", theKey)
            if (!letters_hiragana[vowel]) {
                console.log("ERR: UNRECOGNIZED/BAD VOWEL -> '" + vowel + "'")
                return ""
            }
            let aSound = {...letters_hiragana[vowel], key:theKey}

            console.log("sound: ", aSound)
            return aSound
        })
        setHiraganaSound(hiraganaSoundVowels)
        console.log("hiraganaSoundVowels: ", hiraganaSoundVowels)
        let katakanaSoundVowels = vowels.map((vowel, index) => {
            console.log("vowel: ", vowel)
            let theKey = "key-" + vowel+""+index
            console.log("key:", theKey)
            if (!letters_katakana[vowel]) {
                console.log("ERR: UNRECOGNIZED/BAD VOWEL -> '" + vowel + "'")
                return ""
            }
            let aSound = {...letters_katakana[vowel], key:theKey}

            console.log("sound: ", aSound)
            return aSound
        })
        setKatakanaSound(katakanaSoundVowels)
        console.log("katakanaSoundVowels: ", katakanaSoundVowels)
    }, [japaneseWordSounds])
    function forceLower(strInput) {
        console.log("strInput: ", strInput)
        console.log("strInput.value: ", strInput.value)
        strInput.value = strInput?.value?.toLowerCase()
    }

    function copyHiraganaToClipboard() {
        console.log("hiraganaSound", hiraganaSound)
        const text = hiraganaSound.map(hs=>hs.props.children[0]).join(" ")
        copyTextToClipboard(text)
        alert("copied to clipboard: " + text)
    }

    function copyKatakanaToClipboard() {
        console.log("katakanaSound", katakanaSound)
        const text = katakanaSound.map(ks=>ks.props.children[0]).join(" ")
        copyTextToClipboard(text)
        alert("copied to clipboard: " + text)
    }

    return <div key={"TextToHiragana-div"} className={styles.textToHiraganaDiv}>
        <span key={"input-span"} className={styles.japaneseWordSounds}>
            <input
                className={styles.japaneseWordSoundsInput}
                onChange={(e)=>setJapaneseWordSounds(e.target.value)}
                onKeyUp={(e)=>{return forceLower(e.target);}}
            />
        </span>
        <br/>
        <span key={"title-hiragana"} className={styles.title}>Hiragana</span><br/>
        <span
            key={"hiragana-sound-span"}
            className={styles.soundVowels}
            onClick={()=>copyHiraganaToClipboard()}
        >
            {hiraganaSound}
        </span>
        <br/>
        <span key={"title-katakana"} className={styles.title}>Katakana</span><br/>
        <span
            key={"Katakana-sound-span"}
            className={styles.soundVowels}
            onClick={()=>copyKatakanaToClipboard()}
        >
            {katakanaSound}
        </span>
    </div>;
}
