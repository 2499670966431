import cssStyles from "./HiraganaRecognizer.module.css"

import SignaturePad from 'signature_pad'

import {Button} from "@mui/material";
import {useEffect, useRef, useState} from "react";

export default function HiraganaRecognizer(props) {

    console.log("HiraganaRecognizer")

    let [recognizedText, setRecognizedText] = useState("")
    let [base64image, setBase64Image] = useState()
    useEffect(() => {
        //console.log("calling recognize, base64image: ", base64image)
        if (!base64image || base64image.length === 0) {
            console.log("NOT calling recognize, because base 64 image absent or empty")
            return
        }
        console.log("calling recognize")
        fetch(
            `/.netlify/functions/get-text-from-image-with-google-vision-API`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    base64image: base64image
                })
            }
        )
            .then((res) => {
                console.log("res.json(): ", res)
                return res.json()
            })
            .then((data) => {
                console.log("data: ", data)
                if (!data || data.error) {
                    setRecognizedText("ERROR: " + data?.message)
                } else {
                    if (!data.responses) {
                        setRecognizedText(data)
                    } else if (!data.responses[0]) {
                        setRecognizedText("No response")
                    } else if (!data.responses[0].fullTextAnnotation) {
                        setRecognizedText("No fullTextAnnotation")
                    } else if (!data.responses[0].fullTextAnnotation.text) {
                        setRecognizedText("No text")
                    } else {
                        setRecognizedText(data.responses[0].fullTextAnnotation.text)
                    }
                }
            }).catch((e)=>{
            console.log("error getting data: ", e)
        })
    }, [base64image])

    const [signaturePadRef, setSignaturePadRef] = useState()

    const signaturePadCanvasRef = useRef()
    useEffect(() => {
        //https://github.com/szimek/signature_pad
        let canvaske = signaturePadCanvasRef.current
        const signaturePad = new SignaturePad(canvaske, {
            dotSize: 2,
            minWidth: 2,
            maxWidth: 2,
            penColor: "rgb(0, 0, 0)",
            backgroundColor: "rgb(255, 255, 255)",
            width: 250,
            height: 250
        })
        setSignaturePadRef(signaturePad)
    }, [])

    useEffect(() => {
        function resizeCanvas() {
            if (!signaturePadRef) {
                return
            }
            let canvaske = signaturePadCanvasRef.current
            const ratio =  Math.max(window.devicePixelRatio || 1, 1)
            canvaske.width = canvaske.offsetWidth * ratio
            canvaske.height = canvaske.offsetHeight * ratio
            canvaske.getContext("2d").scale(ratio, ratio)
            console.log("signaturePadRef: ", signaturePadRef)
            signaturePadRef.clear()  //otherwise isEmpty() might return incorrect value
            setRecognizedText("")
        }

        window.addEventListener("resize", resizeCanvas)
        resizeCanvas()
    }, [signaturePadRef])

    function undo() {
        if (!signaturePadRef) {
            return
        }
        let data = signaturePadRef.toData();
        if (data) {
            data.pop(); // remove the last dot or line
            signaturePadRef.fromData(data);
        }
    }

    return (
        <table id={cssStyles.navigationTable}>
            <tbody>
                <tr key={"navtab-tr-signaturepad"}>
                    <td key={"navtab-td-signaturepad"}>
                        <canvas
                            className={cssStyles.sigContainer}
                            ref={signaturePadCanvasRef}
                        />
                    </td>
                </tr>
                <tr key={"navtab-tr-sound"}>
                    <td key={"navtab-td-sound"}>
                        <Button
                            variant="contained"
                            onClick={()=>{undo()}}
                        >Undo</Button>
                        <Button
                            variant="contained"
                            onClick={()=>{signaturePadRef.clear(); setRecognizedText("")}}
                        >Clear</Button>
                        <Button
                            variant="contained"
                            onClick={()=>{setBase64Image(signaturePadRef.toDataURL())}}
                        >Read it!</Button>
                    </td>
                </tr>
                <tr key={"navtab-tr-recognized"}>
                    <td key={"navtab-td-sound"}><span className={cssStyles.recognizedText}>{recognizedText}</span></td>
                </tr>
            </tbody>
        </table>
    )
}
