import cssStyles from "./About.module.css"
import {getFileFormatsSupportInformation} from "../utils";

function formatSupportedFileFormats() {
    return <>
        {getFileFormatsSupportInformation().map(contentType =>
            contentType.type + ' is ' + (contentType.supported ?
                'supported\n' : 'NOT supported \n')
        )}
    </>
}

export default function About(props) {
    return <div className={cssStyles.about}>
        <div className={cssStyles.supportedMediaFormats}><pre>{formatSupportedFileFormats()}</pre></div>
    </div>
}
